import React from "react";
import { Link } from "react-router-dom";
import { FooterNavs } from './constans';

const Footer = () => {
  return (
    <footer className="my-5	w-full flex justify-center border-t border-[#ECE4DE] bg-gray">
      <div className="my-5 w-full flex-col lg:flex-row max-w-screen-xl mx-5 md:mx-20 flex gap-10 justify-between">
        <div className="flex flex-col gap-9">
          <div>
            {/* <Brand /> */}
          </div>
          <div className="w-[327px] md:w-[505px] flex flex-col gap-3">
            <p className="text-black text-sm md:text-lg">
              در آسمان ممبر شما فالوور اینستاگرام ، لایک اینستاگرام و ممبر تلگرام را با قیمت ارزان میتوانید به راحتی سفارش دهید
            </p>
          </div>
        </div>
        <div className="flex justify-between md:gap-28">
          {FooterNavs.map((el, _i) => (
            <div key={_i} className="flex flex-col gap-5">
              <h4 className="text-black font-semibold">{el.category}</h4>
              <div className="flex flex-col gap-5">
                {el.navs.map((nav, _i) => (
                  <h2 key={_i} className="text-black">
                    <Link to={nav.href}>{nav.value}</Link>
                  </h2>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
