
export const FooterNavs  = [
    {
      id: 1,
      category: "خدمات",
      navs: [
        {
          id: 1,
          value: "خرید لایک اینستاگرام",
          href: "https://asemanmember.ir/service/buy-instagram-like/",
        },
        {
          id: 2,
          value: "خرید ویو اینستاگرام",
          href: "https://asemanmember.ir/service/buy-instagram-view/",
        },
        {
          id: 3,
          value: "خرید فالوور اینستاگرام",
          href: "https://asemanmember.ir/service/buy-instagram-follower/",
        },
      ],
    },
  ];